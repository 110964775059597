@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
@layer components {
  .btn {
    @apply px-2 py-2 my-2 font-bold text-white rounded-lg select-none bg-theme-500 hover:bg-sub-500;
  }
  .btn-gray {
    @apply px-2 py-2 my-2 font-bold text-white rounded-lg select-none bg-sub-300 hover:bg-sub-500;
  }
  .btn-sm {
    @apply px-2 py-2 my-2 text-sm font-bold text-white rounded-lg select-none bg-theme-500 hover:bg-sub-500;
  }
  .page {
    @apply flex flex-col flex-1 w-full px-20 py-20 bg-white;
  }
  .pageTitle {
    @apply mb-10 text-4xl font-bold;
  }
  .pageSubtitle {
    @apply mb-5 text-2xl font-bold;
  }
  .card {
    @apply flex flex-row flex-1 px-10 py-10 border-2 border-sub-500;
  }
}

.ant-btn-primary:not(:disabled) {
  background-color: #3b82f6 !important; /* 원하는 색상으로 변경 */
  border-color: #3b82f6 !important; /* 원하는 색상으로 변경 */
}

.swiper-pagination-bullet-active {
  padding-top: 12 !important;
  padding-bottom: 0 !important;
}
body {
  background-color: #f1f2f5;
  margin: 0;
  /* min-width: 1200px; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
table,
th,
td {
  border: 5px solid black;
  border-collapse: collapse;
} */

table {
  width: 100%;
  /* border: 5px solid black; */
  /* border-width: 1.5;
  border-color: #edf0f5; */
  /* background-color: '#edf0f5'; */
  /* border-collapse: collapse; */
  white-space: nowrap;
  border-spacing: 0;
  border: 1px solid #d1d5db;
}

thead {
  background-color: #edf0f5;
  font-size: 16px;
}

tbody {
  font-size: 14px;
  text-align: center;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #d1d5db;
  border-right: 1px solid #d1d5db;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: 0;
  margin-right: 0;
}

.multi-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(
    1.5em * 2
  ); /* 여기서 1.5em은 line-height 값입니다. 필요에 따라 조절하십시오. */
}

/* .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
} */
